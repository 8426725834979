'use client'
import { useMemo } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import useMount from "react-use/lib/useMount";
import styles from './home.module.css'
import PictureCard from 'components/_styled/pictureCard.module.css'
import LazyImage from "components/LazyImage";
import SearchGoogle from "components/Search_Google";
import Buttons from 'components/_styled/buttons.module.css'
import logPageView from "lib/ga/pageView";
import interpretThumbnail from "lib/interpretThumbnail";
import { PropertyType } from "lib/types";
import { interpretAddress } from "lib/interpretAddress";
const PortfoliosSlider = dynamic(() => import("components/PortfoliosSlider"), {
  ssr: false,
});

const News = dynamic(() => import("./News"), {
  ssr: false,
});

interface PropertyTileProps {
  property: PropertyType,
  campaign: string
}


const PropertyTile = ({ property, campaign }: PropertyTileProps): JSX.Element => {
  const { id, url, name, thumbnail } = property;
  let address = interpretAddress(property);
  const thumbnailProps = useMemo(() => {
    if (!thumbnail) return null;
    if (!thumbnail.default) {
      if (!thumbnail.jpg) return null;
      thumbnail.default = thumbnail.jpg['200']
    }
    return interpretThumbnail(thumbnail, address[0], "", "", "(min-width:80rem) 40vw, 20vw", 200);
  }, [name, thumbnail]);

  let utmUrl = `${url}?utm_source=CR&utm_medium=website&utm_campaign=${campaign}&utm_content=home-page`

  return (
    <Link key={id} href={utmUrl} passHref legacyBehavior prefetch={false}>
      <a className={PictureCard.PictureCard_background}>
        <div className={`${styles.rounded} ${PictureCard.PictureCard__Inner}`}>
          <p className={PictureCard.PictureCard__Upper}>{address[0]}</p>
          <h3 className={PictureCard.PictureCard__Lower} >{address[1]}</h3>
        </div>
        <LazyImage {...thumbnailProps} radius={true} sizes={"(min-width:80rem) 40vw, 20vw"} className="rounded" />
      </a>
    </Link>
  );
};

const NewPropertyTitles = ({ property, campaign }: PropertyTileProps) => {
  const { id, url, thumbnail, rent, availability, size_ft } = property;
  let address = interpretAddress(property);
  const thumbnail_uri = thumbnail?.webp?.['200'] ? `${process.env.NEXT_PUBLIC_APP_MEDIA_URL}${thumbnail.webp['200']}` : `${process.env.NEXT_PUBLIC_APP_CDN_URL}/fallback-images/sc-fullback.jpeg`;
  let utmUrl = `${url}?utm_source=CR&utm_medium=website&utm_campaign=${campaign}&utm_content=home-page`;
  return (
    <Link key={id} href={utmUrl} passHref legacyBehavior prefetch={false}>
      <a className={styles.PictureCardContainer}>
        <div className={styles.PictureCardImage}>
          <LazyImage
            src={thumbnail_uri}
            layout="fill"
            objectFit="cover"
            alt={address.join(' ')}
          />
        </div>
        <div className={styles.PictureCardInfo}>
          <h3>
            {address[0]}
            <span> {address[1]}</span>
          </h3>
          <div>
            {size_ft && (<p>Size: {size_ft} ft²</p>)}
            <p>Rent: { rent && !isNaN(Number(rent))  ? `£${Number(rent).toLocaleString()} pa` : "POA"}</p>
            {availability && (<p>Availability: {availability}</p>)}
          </div>
        </div>
      </a>
    </Link>
  );
}

type news = {
  _source: {
      permalink: string,
      post_title: string,
      post_date: string,
      post_content: string,
      post_id: number
  }
}
type Props = {
  portfolios: [], newProperties: object, structuredData: string, featuredProperties: object, totalProps: string, news: news[], totalSchemeUnitsProps: string
}

const Home = ({ portfolios, newProperties, structuredData, featuredProperties, totalProps, news, totalSchemeUnitsProps }: Props) => {
  useMount(() => {
    logPageView({ url: window.location.pathname + window.location.search });
  });
  return (
    <main className={styles.homeWrapper} >
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />
      {/* Search */}
      <section className={styles.homeSearch}>
        <div className={styles.SearchInner}>
          <h1>Find your next shop</h1>
          <p>Looking for a shop to lease or rent?</p>
          <p>You are in the right place.  Whatever type of shop you are looking for Completely Retail has the widest range available.  Start by letting us know your ideal location in the search box below.</p>
          {/* <SearchInput useUrlParams /> */}
          <SearchGoogle />
        </div>

        <div className={styles.heroImage} />
      </section>

      {/* Featured */}
      {Array.isArray(featuredProperties) &&
        <section className={`${styles.FeaturedNew} ${styles.FourColumn}`}>
          <h2 className={styles.Heading2}>Featured Properties</h2>
          <p className={styles.subHead}>A selection of retail properties that have recently come to market</p>
          {
            featuredProperties.slice(0, 4).map((property: PropertyType) => {
              return <NewPropertyTitles key={property.id} property={property} campaign='featured_properties' />;
            })
          }
          {/*  <Advertisement/> */}
        </section>
      }

      <div className={styles.searchOptions}>
        <div className={`${styles.searchOptionsContainer} ${styles.nearMe}`}>
          <h2>Looking for nearby shops to rent?</h2>
          <p>
            Looking for nearby shops to rent?<br />
            We have shops to rent big and small, so whatever you’re looking for we’ll have a shop for you.
          </p>
          <Link href={"/shops-to-rent/near-me"} passHref prefetch={false} legacyBehavior>
            <a className={Buttons.primaryButton}>Search shops near me</a>
          </Link>
        </div>

        <div className={`${styles.searchOptionsContainer} ${styles.forSale}`}>
          <h2>Looking for shops for sale?</h2>
          <p>
            Looking to buy a shop? <br />
            We have shops for sale in all sizes so whatever you’re looking for we’ll have a shop for you.
          </p>
          <Link href={"/shops-for-sale"} passHref prefetch={false} legacyBehavior>
            <a className={Buttons.primaryButton}>Search shops for sale</a>
          </Link>
        </div>
      </div>
      {/* Latest */}
      {Array.isArray(newProperties) &&
        <section className={`${styles.latest} ${styles.FourColumn}  ${styles.FourColumnOld}`}>
          <h2 className={styles.Heading2}>Latest Properties</h2>
          <p className={styles.subHead}>
            We are currently listing <strong>{parseInt(totalProps).toLocaleString()}</strong> shops to rent or for sale across the UK
          </p>
          {newProperties.slice(0, 8).map((property) => (
            <PropertyTile key={property.id} property={property} campaign={""} />
          ))}
          <div className={styles.latestLink}>
            <Link href={"/new-shops-to-rent"} passHref prefetch={false}  legacyBehavior>
              <a className={Buttons.primaryButton}>View Latest Properties</a>
            </Link>
          </div>
        </section>
      }

      {/* Scheme Search */}

      <section className={styles.schemeSearch}>
        <h2 className={styles.Heading2}>Search for shopping centre, leisure and retail park property</h2>
        <p className={styles.subHead}>
          Search over <strong>{parseInt(totalSchemeUnitsProps).toLocaleString()}</strong>{' '}
          shopping centre, leisure park and retail park scheme units for rent
          or sale by location or name
        </p>
        <SearchGoogle searchType="SCHEME" border={true} />
      </section>

      {/* Portfolios */}
      <section className={styles.portfolios} >
        <PortfoliosSlider portfolios={portfolios} />
        <Link href={"/portfolio"} passHref prefetch={false} legacyBehavior>
          <a className={Buttons.primaryButton}>View All Portfolios</a>
        </Link>
      </section>

      <News newsArticles={news} />

      {/* CRMP */}
      {/* <CRMP>
        <h2>Completely Retail Marketplace</h2>
        <a href="https://www.crmarketplace.com/soapbox/" target="_blank" rel="noopener" >
          <LazyImage
            alt="Completely Retail Marketplace UK – 30th April 2024, Old Billingsgate Market, London"
            src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}home/soapbox_600.png`}
            sources={[
              {
                srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}home/soapbox_400.webp 400w, ${process.env.NEXT_PUBLIC_APP_CDN_URL}home/soapbox_600.webp 600w`,
                type: "image/webp",
                sizes: "(min-width: 48rem) 60vw, (min-width: 21rem) 40vw, 100vw"
              },
              {
                srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}home/soapbox_400.png 400w, ${process.env.NEXT_PUBLIC_APP_CDN_URL}home/soapbox_600.png 600w`,
                type: "image/jpg",
                sizes: "(min-width: 48rem) 60vw, (min-width: 21rem) 40vw, 100vw"
              },
            ]}
            layout="fill"
            objectFit="contain"
            sizes="(min-width: 48rem) 60vw, (min-width: 21rem) 40vw, 100vw"
          />
        </a>
        <a href="https://www.crmarketplace.com/events/london/" target="_blank" rel="noopener">
          <LazyImage
            alt="COMPLETELY RETAIL MARKETPLACE NORDICS"
            src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}home/uk_event_600.png`}
            sources={[
              {
                srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}home/uk_event_400.webp 400w, ${process.env.NEXT_PUBLIC_APP_CDN_URL}home/uk_event_600.webp 600w`,
                type: "image/webp",
                sizes: "(min-width: 48rem) 60vw, (min-width: 21rem) 40vw, 100vw"
              },
              {
                srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}home/uk_event_400.png 400w, ${process.env.NEXT_PUBLIC_APP_CDN_URL}home/uk_event_600.png 600w`,
                type: "image/jpg",
                sizes: "(min-width: 48rem) 60vw, (min-width: 21rem) 40vw, 100vw"
              },
            ]}
            layout="fill"
            objectFit="contain"
            sizes="(min-width: 48rem) 60vw, (min-width: 21rem) 40vw, 100vw"
          />
        </a>
      </CRMP> */}
    </main>
  );
};

export default Home;
