import { ResultThumbnail } from "lib/types";

type Source = {
  srcSet: string;
  type: string;
  sizes?: string;
}

type interprettedThumbnail = {
  layout: string;
  objectFit: string;
  alt?: string;
  src?: string;
  sources?: Source[];
  sizes?: string;
}

const interpretThumbnail = (
  thumbnail: ResultThumbnail,
  alt: string,
  type: string = "",
  category: string = "",
  sizes: string = "",
  maxSize: number = 800
) => {
  let newProps: interprettedThumbnail = {
    layout: "fill",
    objectFit: "cover",
  };
  if (alt) newProps.alt = alt;
  if (!thumbnail?.origin) {

    // return null
    newProps.src = thumbnail?.default;
    if(newProps.src) {
      var filename = newProps.src.substring(newProps.src.lastIndexOf('/')+1);
      var name = filename.split(".")[0];
      newProps.sources = [
        {
          srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}fallback-images/${name}.webp`,
          type: "image/webp",
        },
        {
        srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}fallback-images/${name}.jpeg`,
        type: "image/jpg",
      }
      ];
    }

    //   type.toLowerCase() === "scheme" && category.toLowerCase() === "sc"
    //     ? "/images/_shared/scheme-sc-fallback.jpeg"
    //     : "/images/_shared/" + category.toLowerCase() + "-fallback.jpeg";
  } else {
    newProps.src = thumbnail?.default;

    // if ("jpg" in thumbnail) {
    //   newProps.src = process.env.NEXT_PUBLIC_APP_MEDIA_URL + thumbnail.jpg[200];
    // } else if ("png" in thumbnail) newProps.src = process.env.NEXT_PUBLIC_APP_MEDIA_URL + thumbnail.png[200];
    newProps.sources = [];

    if ("webp" in thumbnail && thumbnail.webp) {
      newProps.sources.push({
        srcSet: Object.keys(thumbnail.webp)
          .map((val) => {
            if (Number(val) <= maxSize) {
              if (thumbnail?.webp?.[val]) {
                return `${process.env.NEXT_PUBLIC_APP_MEDIA_URL + thumbnail.webp[val]} ${val}w`;
              }
            }
          })
          .filter((x) => typeof x === "string" && x.length > 0)
          .join(),
        type: "image/webp",
        sizes,
      });
    }
    if ("png" in thumbnail && thumbnail.png) {
      newProps.sources.push({
        srcSet: Object.keys(thumbnail.png)
          .map((val) => {
            if (Number(val) <= maxSize) {
              if (thumbnail?.png?.[val]) {
                return `${process.env.NEXT_PUBLIC_APP_MEDIA_URL + thumbnail.png[val]} ${val}w`;
              }
            }
          })
          .filter((x) => typeof x === "string" && x.length > 0)
          .join(),
        type: "image/png",
        sizes,
      });
    }
    if ("jpg" in thumbnail && thumbnail.jpg) {
      newProps.sources.push({
        srcSet: Object.keys(thumbnail.jpg)
          .map((val) => {
            if (Number(val) <= maxSize) {
              if (thumbnail?.jpg?.[val]) {
                return `${process.env.NEXT_PUBLIC_APP_MEDIA_URL + thumbnail.jpg[val]} ${val}w`;
              }
            }
          })
          .filter((x) => typeof x === "string" && x.length > 0)
          .join(),
        type: "image/jpeg",
        sizes,
      });
    }
  }
  // CR-1742 - broken images
  if (newProps.src && !newProps?.src?.startsWith('https://') && !newProps?.src?.startsWith('/images') && !newProps?.src?.startsWith('fallback-images')) {
    newProps.src = process.env.NEXT_PUBLIC_APP_MEDIA_URL + newProps?.src;
  } else if (newProps?.src?.startsWith('fallback-images')) {
    newProps.src = process.env.NEXT_PUBLIC_APP_CDN_URL + newProps?.src;
  }
  return newProps;
};

export default interpretThumbnail;
