import CRLogoLoader from "components/svg/CogRoundelLoader";
import { useEffect, useRef, useCallback, useState, FormEvent } from "react";
import logEvent from "lib/ga/miscEvent";
import { useSearchParams } from "next/navigation";
import logNewSearchSubmit from "lib/ga/newSearchSubmit";
import data from 'data/destinations/searchLocations.json';
import styles from './SearchGoogle.module.css';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

type section = {
  name: string,
  address_components: any,
  formatted_address: string,
  geometry: any,
  types: any
}


type CustomPrediction = google.maps.places.AutocompletePrediction & {
  url?: string;
};


type Props = {
  type?: string,
  searchPage?: boolean,
  setPreventBlur?: any,
  category?: string | null,
  forSale?: boolean
}

const GoogleSearchInput = ({ type, searchPage = false, setPreventBlur = false, category = null, forSale = false }: Props) => {
  if (!type) {
    type = "PROPERTY"
  }

  const [selection, setSelection] = useState<section | null>(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [inputSelected, setInputSelected] = useState(null);
  let inputRef = useRef<HTMLInputElement>(null);
  let query = useSearchParams();
  const places = useMapsLibrary('places');
  const geoCoderLib = useMapsLibrary('geocoding')
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  const [geoCoder, setGeoCoder] = useState<any | null>(null)

  const [predictionResults, setPredictionResults] = useState<Array<CustomPrediction | google.maps.places.AutocompletePrediction>| null>([]);

  useEffect(() => {
    if (!places || !geoCoderLib) return;
    setAutocompleteService(new places.AutocompleteService());
    setGeoCoder(new geoCoderLib.Geocoder())
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [places, geoCoderLib]);


  const leaveSearch = (e) => {
    const target = document.getElementById('search-suggestions');
    if (target) {
      if (e.target.id !== 'google-search-input') {
        target.style.display = 'none'
      } else {
        target.style.display = 'unset'
      }
    }
  }

  document.body.addEventListener('click', leaveSearch, true);



  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }
      const request = { input: inputValue, sessionToken, componentRestrictions: { country: ["gbr"] }, types: ['locality', 'sublocality', 'postal_code', 'administrative_area_level_2', 'route'] };
      const res = await autocompleteService.getPlacePredictions(request);
      const predictions = res.predictions;
      if (predictions) {
        let keys = Object.keys(data)
        let custom: CustomPrediction[] = [];
        let length = inputValue.length
        keys.forEach((key) => {
          const keyCut = key.slice(0, length)
          const add = data[key].type === 'bid' ? predictions[0]?.structured_formatting?.main_text.toLowerCase().match(key) : keyCut.includes(inputValue.toLowerCase());
          if (add) {
            const location = data[key]
            const url = location.type === 'bid' ? `/destinations/${location.url}` : [(type === 'SCHEME' ? '/retail-schemes' : forSale ? '/shops-for-sale' : '/shops-to-rent'), location.url].join('/')
            predictions.pop();
            custom.push({
              'description': location.display_name,
              'place_id': 'custom_location',
              'types': [],
              'url': url,
              matched_substrings: [],
              structured_formatting: {
                main_text: "",
                main_text_matched_substrings: [],
                secondary_text: ""
              },
              terms: []
            })
          }
        });
        setPredictionResults(custom.concat(predictions));
      } else {
        setPredictionResults(predictions)
      }
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value;
      setInputValue(value);
      fetchPredictions(value);
    },
    [fetchPredictions]
  );

  const onInputDropdownSelect = (object) => {
    setInputSelected(object);
    setInputValue(object.description)
    onSelect(object)
  }

  const handleCustom = (place) => {
    setIsRedirecting(true);
    logEvent({
      category: 'Search Google - destination',
      action: "User made a search",
      label: place.url,
    });
    logNewSearchSubmit({
      type: type,
      category: 'Search Google - destination',
      criteria: 'LOCATION',
      url: place.url,
    });

    window.location = place.url;
  }

  const getLocationDetails = (e) => {
    e.preventDefault();
    if (inputSelected !== null) {
      onSelect(inputSelected);
    } else if (predictionResults && predictionResults.length > 0) {
      onSelect(predictionResults[0])
    }
  }

  const onSelect = useCallback(
    (prediction: google.maps.places.AutocompletePrediction) => {

      if (prediction.place_id === 'custom_location') {
        handleCustom(prediction)
      }

      geoCoder.geocode({
        placeId: prediction.place_id
      }, (res) => {
        if (res && Array.isArray(res) && res.length > 0) {
          let newPlace = {
            name: prediction.structured_formatting.main_text,
            ...res[0]
          }
          setSelection(newPlace);
        }
      })
    },
    [geoCoder]
  );


  //for google location redirect
  useEffect(() => {
    let input = document.getElementById("google-search-input");
    let currentFocus = 0;
    input?.addEventListener("keydown", function (e) {
      let x: HTMLElement | null = document.getElementById("search-suggestions");
      if (x !== null) {
        let div = x.getElementsByTagName("div");
        let length = div.length;
        if (e.key == 'ArrowDown') {
          e.preventDefault();
          currentFocus++;
          if (currentFocus > length) {
            currentFocus = 1;
          }

          addActive(div, currentFocus);

        } else if (e.key == 'ArrowUp') {
          e.preventDefault();

          if (currentFocus === 0 || currentFocus === 1) {
            currentFocus = length;
          } else if (currentFocus !== 1) {
            currentFocus--;
          }

          addActive(div, currentFocus);

        } else if (e.key === "Enter") {
          if (currentFocus !== 0) {
            console.log(div, currentFocus)
            if (div) div[currentFocus - 1].click();
          } else {
            if (div) div[0].click();
          }
        } else {
          currentFocus = 0;
          removeActive(div);
        }
      }
    })



    const addActive = (x, currentFocus) => {
      if (!x) return false;
      currentFocus = currentFocus - 1
      removeActive(x);
      x[currentFocus].classList.add(styles.autocompleteActive);
    }

    function removeActive(x) {
      if (!x) return false;
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove(styles.autocompleteActive);
      }
    }

    if (selection && selection.geometry) {
      setIsRedirecting(true);
      let searchCategory = "Search Google";
      let searchType: string | null = null;

      let types = {
        city: [
          "locality"
        ],
        place: [
          "sublocality",
          "point_of_interest",
          "establishment",
          'premise'
        ],
        address: [
          "route"
        ],
        postcode: [
          "postal_code"
        ],
      }
      if (selection.types) {
        for (let i = 0; i < selection.types.length; i++) {
          let currType = selection.types[i];

          Object.keys(types).map((key) => {
            let currArr = types[key];
            if (currArr.includes(currType)) {
              searchCategory = `Search Google - ${key}`;
              searchType = key;
            }
          });
        }
        selection.types.forEach(type => {
        });
      }

      let uriString = selection.name.replaceAll(" ", "-").toLowerCase();
      let locationName = selection.name;

      if (searchType && selection.address_components && Array.isArray(selection.address_components)) {

        let ac = selection.address_components;

        if (searchType == 'city') {
          uriString = `${ac[0].long_name}`
          locationName = ac[0].long_name;
        }
        if ((searchType == 'place' || searchType == 'address') && ac.length > 1) {
          uriString = `${selection.name}-${ac[1].long_name}`
          locationName = ac[1].long_name;
        }
        if (searchType == 'postcode' && ac.length > 2) {
          uriString = `${ac[0].long_name}-${ac[1].long_name}-${ac[2].long_name}`
          locationName = ac[2].long_name;
        }
      } else {
        let address = selection.formatted_address;
        uriString = address || uriString;
      }



      let url = '';
      let uri = uriString.replaceAll(",", "").replaceAll(" ", "-").replaceAll("'", "").toLowerCase();
      let geo = selection.geometry;
      let lat = geo.location.lat();
      let lng = geo.location.lng();

      let params = {
        lat: lat,
        lng: lng,
        name: locationName
      }

      let n = 0;
      let e = 0;
      let s = 0;
      let w = 0;

      if (geo?.bounds) {
        n = geo?.bounds.getNorthEast().lat();
        e = geo?.bounds.getNorthEast().lng();
        s = geo?.bounds.getSouthWest().lat();
        w = geo?.bounds.getSouthWest().lng();

        let toAdd = 2000 / 111139;
        if (searchType == "city") {
          toAdd = 1000 / 111139;
        }
        if (searchType == "place" || searchType == "address" || searchType == "postcode") {
          toAdd = 500 / 111139;
        }

        n += toAdd;
        e += toAdd;
        s -= toAdd;
        w -= toAdd;

        params['n'] = n;
        params['e'] = e;
        params['s'] = s;
        params['w'] = w;
      }


      let selectionParams = `${uri}`;
      switch (type) {
        case "PROPERTY":
          url = forSale ? `/shops-for-sale/${selectionParams}` : `/shops-to-rent/${selectionParams}`;
          break;

        case "POPUP":
          url = `/flexible-units/${selectionParams}`;
          break;
        case "SCHEME":
          url = `/retail-schemes/${selectionParams}`;
          break;
        case "CATEGORY":
          url = `/${category}/${selectionParams}`;
          break;
      }
      logEvent({
        category: searchCategory,
        action: "User made a search",
        label: url,
      });
      logNewSearchSubmit({
        type: type,
        category: searchCategory,
        criteria: 'LOCATION',
        url: url,
      });
      let paramsString = ""

      
      if(query) {
        let ignore = ['e', 'lat', 'lng', 'location', 'n', 'name', 's', 'w'];
        Object.keys(query).forEach(key => {
          if (!ignore.includes(key)) {
            if (paramsString == "") {
              paramsString = `${key}=${query[String(key)]}`
            } else {
              paramsString += `&${key}=${query[String(key)]}`
            }
          }
        })
      }

      Object.keys(params).forEach(key => {
        if (paramsString == "") {
          paramsString = `${key}=${params[key]}`
        } else {
          paramsString += `&${key}=${params[key]}`
        }
      });
      url += `?${paramsString}`;
      window.location.href = url;

      setTimeout(() => {
        setIsRedirecting(false)
        const inputElement = document.getElementById('google-search-input') as HTMLInputElement;
        if (inputElement) {
          inputElement.value = '';
        }


      }, 1500);
    }

  }, [selection]);

  return <>
    <input
      id="google-search-input"
      ref={inputRef}
      type="text"
      placeholder="Search by city, town, street or postcode"
      disabled={isRedirecting}
      onChange={onInputChange}
      autoComplete="off"
      value={inputValue}
    />

    {predictionResults && predictionResults.length > 0 &&
      <div className={styles.SearchSuggestions} id="search-suggestions">
        {predictionResults.map((object, i) =>

          <div id={`suggesstion-${i}`} className={styles.SearchItem} key={i} onMouseDown={() => {
            if (setPreventBlur) {
              setPreventBlur(true)
            }
          }} onClick={() => onInputDropdownSelect(object)}
          >
            <span className={styles.SuggestionIcon}></span>
            <span className={styles.SuggestionText}>{object.description}</span>
          </div>
        )}
      </div>}
    {!isRedirecting &&
      <button className={styles.googleSearchButton} onClick={(e) => getLocationDetails(e)}>
        Search
      </button>
    }
    {isRedirecting && <div className={styles.googleSearchButton}>
      <div className={styles.Loader}>
        <CRLogoLoader
          gradToUse={type === "POPUP" ? "flexible" : "retail"}
        />
      </div>
    </div>}
  </>
}

export default GoogleSearchInput;